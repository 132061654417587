<template>
  <aside class="left-menu-block">
    <div class="radio-block">
      <a
        class="radio-block__button"
        href="https://radio-na.ru/#listen"
        target="_blank"
        rel="noopener noreferrer"
        @click="metrickaBtn"
      >
        <i class="fas fa-broadcast-tower"></i>Радио АН
      </a>
    </div>
    <!-- <div class="radio-block">
      <router-link
        class="radio-block__button"
        to="/registration"
      >
        Регистрация на юбилей
      </router-link>
    </div> -->
    <div class="book-block">
      <a
        href="https://na-russia.org/eg"
        target="_blank"
        rel="noopener noreferrer"
        class="desctop"
      >
        <img
          src="../assets/bookMain.webp"
          alt="Только сегодня"
        />
        <span class="book-block__date">{{ nowDate }}</span>
      </a>
      <a
        href="https://na-russia.org/eg"
        target="_blank"
        rel="noopener noreferrer"
        class="mobile"
      >
        <i class="fas fa-book"></i>
        <span class="mobile__header">Ежедневник</span>

        <span class="book-block__date">{{ nowDate }}</span>
      </a>
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      date: new Date(),
    };
  },

  methods: {
    metrickaBtn() {
      this.$metrika.reachGoal("btn_raido_main");
    },
  },

  components: {},

  computed: {
    nowDate() {
      return new Date(this.date).toLocaleDateString();
    },
  },
};
</script>

<style lang="scss">
.left-menu-block {
  flex-basis: 19%;
  position: sticky;
  top: 96px;
  height: fit-content;
  @media screen and (max-width: 1014px) {
    flex-basis: 100%;
    z-index: 150;
    min-width: 100%;
  }
}

.radio-block {
  font-size: 32px;
  color: #000;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 1500px) {
    font-size: 28px;
  }

  &__button {
    margin: 12px 0;
    border: 2px solid #000;
    border-radius: 5px;
    padding: 12px;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.6s;
    width: 100%;
    text-align: center;

    i {
      margin-right: 12px;
    }

    &:hover {
      color: #fff;
      background: #000;
      -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    }
  }
}

.book-block {
  position: relative;
  &__date {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 40px;
    @media (max-width: 600px) {
      position: relative;
      font-size: 1rem;
      color: #000;
      top: auto;
      bottom: auto;
      left: auto;
      transform: translate(0, 0);
      //   margin: 0 auto;
      text-shadow: 3px 0px 15px rgba(255, 255, 255, 1);
    }
  }
}

.mobile {
  display: none;
  text-decoration: none;
  &__header {
    font-size: 1rem;
    text-shadow: 3px 0px 15px rgba(255, 255, 255, 1);
  }
}

@media (max-width: 1014px) {
  .radio-block,
  .desctop,
  .mobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .book-block {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background: #1c7fe2;
    border-radius: 1rem 1rem 0 0;
  }
  .mobile {
    padding: 2px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-gap: 0 12px;

    color: #000;
    font-size: 2rem;
    i {
      grid-row: 1 / 3;
      margin-left: auto;
    }
  }
}
</style>