import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import EasySlider from "vue-easy-slider";
import VModal from "vue-js-modal";
import { Tabs, Tab } from "vue-tabs-component";
import VueYandexMetrika from "vue-yandex-metrika";

const metrikaConf={
  id: 94680082,
  router: router,
  env: 'production',
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  }
}

Vue.use(EasySlider);
Vue.use(VModal);
Vue.use(VueYandexMetrika, metrikaConf);

Vue.component("tabs", Tabs);
Vue.component("tab", Tab);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
