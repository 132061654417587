import Vue from 'vue'
import Vuex from 'vuex'
import addNews from './modules/addnews'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    userData: null
  },
  mutations: {
    setUserData(state, data) {
      state.userData = data;
      console.log(state.userData);
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('http://localhost:3300/login', credentials) // Замените на ваш URL авторизации
        // Сохраняем токен (если он есть) в localStorage
        if (response.data.id) {
          localStorage.setItem('token', response.data.id)
          // Устанавливаем заголовок авторизации для будущих запросов
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.id}`
        }
        commit('setUserData', response.data.user) // Сохраняем данные пользователя в хранилище
      } catch (error) {
        console.error(error)
        // Обработка ошибки авторизации
      }
    },
    fetchUserData({ commit }) {
      const token = localStorage.getItem('token')
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        axios.get('http://localhost:3300/login') // Замените на ваш URL получения данных пользователя
          .then(response => {
            commit('setUserData', response.data)
          })
          .catch(error => {
            console.error(error)
            // Обработка ошибки получения данных
          })
      }
    },
    logout({ commit }) {
      localStorage.removeItem('token')
      delete axios.defaults.headers.common['Authorization']
      commit('setUserData', null)
    }
  },
  
  modules: {
    addNews
  }
})
