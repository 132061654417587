<template>
  <footer class="footer-body">
    <!-- <social-links /> -->
    <phones />
  </footer>
</template>

<script>
import SocialLinks from "./Header/SocialLinks";
import Phones from "./Header/Phones";

export default {
  components: {
    SocialLinks,
    Phones,
  },
};
</script>

<style lang="scss">
.footer-body {
  color: #000;
  background-color: #1c7fe2;
  width: 100%;
  margin: 8px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;

  @media (max-width: 800px) {
    margin-bottom: 46px;
    .logo-contact{
      display: grid;
      justify-content: center;
    }
    .logo-contact__text{
      text-align: center;
    }
    .logo-contact__phones {
      flex-flow: column;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 6px 4px;
    flex-direction: column;
    .logo-contact {
      flex-direction: row;
      &__numb {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .logo-contact {
      flex-direction: column;
    }
  }
}
</style>