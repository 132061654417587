<template>
  <div id="app" ref="main">
    <header-top />
    <menu-top />
    <div class="container body">
      <left-nav-menu />
      <router-view />
    </div>
    <social-links />
    <!-- <login-icon :role="data.role" /> -->
    <footer-bottom />
  </div>
</template>

<script>
import HeaderTop from "@/components/HeaderTop";
import LeftNavMenu from "@/components/LeftNavMenu";
import FooterBottom from "@/components/FooterBottom";
import MenuTop from "@/components/MenuTop";
import axios from "axios";
import SocialLinks from "./components/Header/SocialLinks.vue";
import LoginIcon from "@/components/LoginIcon";

export default {
  components: {
    HeaderTop,
    LeftNavMenu,
    FooterBottom,
    MenuTop,
    SocialLinks,
    LoginIcon,
  },
  data() {
    return {
      siteName: "na-sml -",
      // urlMongoDb: 'http://31.31.198.241/login',
      getSeeeionLogin: "",
      data: [],
    };
  },
  mounted() {
    console.log(this.$refs.main.clientWidth);
    
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;1,400&display=swap");
@import url("https://use.fontawesome.com/releases/v5.0.13/css/all.css");

$main-color-blue: #1c7fe2;
$black-color: #000;
$white-color: #fff;

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background: url("./assets/pattern.png");
}

img,
iframe {
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

.btn {
  border: 2px solid #000;
  border-radius: 5px;
  text-align: center;
  padding: 12px;
  cursor: pointer;
  transition: all 0.5s;
  margin: 12px 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;

  &:not(.btn_address):hover {
    color: #000;
    background: #1c7fe2;
    border-color: #1c7fe2;
  }

  &_address {
    border: none;
  }
}

#app {
  font-family: "Roboto", sans-serif;
  color: $main-color-blue;
  display: flex;
  flex-direction: column;
}

.header-title {
  font-size: 48px;
  margin: 0 0 24px;

  &_sub {
    font-size: 40px;
    margin: 0 0 12px;
    @media (max-width: 600px) {
      font-size: 36px;
    }
  }
  &_mob {
    word-wrap: anywhere;
  }

  @media screen and (max-width: 600px) {
    font-size: 22px;

    &_sub {
      font-size: 22px;
    }
  }
}

.text-block {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 10px;

  strong {
    font-weight: 600;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.container {
  margin: 0 auto;
  max-width: 1600px;
  width: calc(100% - 40px);
}

.body {
  display: grid;
  grid-template-columns: 304px 1fr;
  gap: 20px;
  justify-content: space-between;
  min-height: 74vh;

  @media (max-width: 1014px) {
    grid-template-columns: 1fr;
  }
}

.container-nav {
  max-width: 1600px;
  width: calc(100% - 40px);
  margin: 0 auto;
}

.other-link {
  text-decoration: none;
  color: #000;
  transition: all 0.5s;

  &:hover {
    color: $main-color-blue;
  }
}

.close-block {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    max-width: 385px;
    @media screen and (max-width: 600px) {
      margin: 12px;
      text-align: center;
    }
    &-main {
      color: #fff;
      font-size: 22px;
      margin: 0;
    }
  }
}

.content {
  width: 80%;
  @media screen and (max-width: 1014px) {
    flex-basis: 100%;
    width: 100%;
    order: -1;
  }
}

.button-new {
  margin: 12px 0;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 12px;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.6s;
  width: 100%;
  text-align: center;
  cursor: pointer;

  i {
    margin-right: 12px;
  }

  &:hover {
    color: #fff;
    background: #000;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  }
}
.link-tabs {
  .tabs-component-tabs {
    list-style: none;
    display: flex;
    .tabs-component-tab {
      width: 50%;
      text-align: center;
      padding-bottom: 1rem;
    }
  }

  a {
    text-decoration: none;
    color: #000;
    font-size: 2rem;
  }
  .is-active {
    border-bottom: 1px solid #000;
    a {
      color: #1c7fe2;
    }
  }
}
.router-link {
  color: rgba($color: #1c7fe2, $alpha: 1);
  text-decoration: none;
  transition: all 0.5s;
  &:hover {
    color: rgba($color: #1c7fe2, $alpha: 0.7);
  }
}
</style>
