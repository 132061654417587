<template>
  <ul class="router-items container-nav">
    <li class="router-item" @click="openMH">
      <router-link to="/">Главная</router-link>
    </li>
    <li class="router-item" @click="openMH">
      <router-link to="/timetable">Расписание групп</router-link>
    </li>
    <li class="router-item" @click="openMH">
      <router-link to="/literature">Литература</router-link>
    </li>
    <li class="router-item" @click="openMH">
      <router-link to="/newmember">Новичку</router-link>
    </li>
    <li class="router-item" @click="openMH">
      <router-link to="/members">Участнику</router-link>
    </li>
    <li class="router-item" @click="openMH">
      <router-link to="/howcan">Кто может стать членом?</router-link>
    </li>
    <!-- <li class="router-item" @click="openMH"><router-link to="/news">Новости</router-link></li> -->
    <li class="router-item" @click="openMH">
      <router-link to="/twelvestep">Программа 12 шагов</router-link>
    </li>
    <li class="router-item" @click="openMH">
      <router-link to="/an-meeting">Что происходит на собраниях</router-link>
    </li>
    <li class="router-item" @click="openMH">
      <router-link to="/open-meeting">Что такое открытое собрание</router-link>
    </li>
    <!-- <li class="router-item" v-click-outside="hide" @click="toggleSub">
      <span class="router-item__sub">
        Еще
        <i class="fas fa-caret-down" v-if="subMenu"></i>
        <i class="fas fa-caret-right" v-else></i>
      </span>
    </li>
    <transition name="fade">
      <nav class="left-menu" v-show="subMenu">
        <left-menu-list :subMenu="subMenu" @clickItem="toggleSub" />
      </nav>
    </transition> -->
  </ul>
</template>


<script>
// import LeftMenuList from "@/components/Navmenu/LeftMenuList";
// import ClickOutside from "vue-click-outside";

export default {
  props: ["openMH"],
  data() {
    return {
      subMenu: false,
    };
  },
  methods: {
    toggleSub() {
      this.subMenu = !this.subMenu;
    },
    hide() {
      this.subMenu = false;
    },
  },
  // components: {
  //   LeftMenuList,
  // },
  mounted() {
    this.popupItem = this.$el;
  },

  // directives: {
  //   ClickOutside,
  // },
};
</script>

<style lang="scss">
.router-items {
  display: flex;
  gap: 12px;
  list-style: none;
  font-size: 20px;
  padding: 18px 0;
  flex-flow: wrap;
  width: 100vw;

  @media (max-width: 990px) {
    flex-direction: column;
    position: absolute;
    top: 3rem;
    background: #1c7fe2;
    width: 100%;
    border-radius: 0 0 5px 5px;
    text-align: right;
    padding-right: 12px;
    font-size: 18px;
  }

  @media (max-width: 600px) {
    &.container-nav {
      width: 100vw;
    }
  }
}

.router-item {
  //margin-left: 12px;

  a {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition: all 0.5s;
    height: 100%;

    &:hover {
      color: #000;
    }
    @media (max-width: 990px) {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;
      padding: 6px;
    }
  }

  &__sub {
    color: #fff;
    cursor: pointer;
    i {
      margin-left: 6px;
    }
  }

  @media (max-width: 990px) {
    //margin-bottom: 12px;
  }
  .router-link-exact-active {
    color: #000;
    font-weight: 500;
  }
}

.left-menu {
  position: absolute;
  background: #1c7fe2;
  border-radius: 5px;
  color: #fff;
  max-height: 300px;
  left: 62%;
  top: 3.4rem;

  @media (max-width: 1014px) {
    min-width: 100%;
    overflow: hidden;
    top: 51px;
  }

  @media (max-width: 990px) {
    top: 23rem;
    left: 0;
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>