
export default {
    state: { 
        news: []
    },
    mutations: {
        updateNews(state, news){
            state.news = news
        },
        createNews(state, addNews){
            state.news.unshift(addNews)
        }
    },
    actions: {
        async fetchNews(ctx){
            const res = await fetch(
                "/api/addNews"
            );
            const news = await res.json();
            ctx.commit('updateNews', news)
        },
        async addNews(ctx , addNews){
            let res = await fetch(
                    "/api/addNews",
                    {
                        method: 'POST',
                        mode: 'no-cors',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(addNews)
                    }
                )
            let record = await res.json();
            ctx.commit('createNews', record);
        },
    },
    getters: {
        allNews(state){
            return state.news
        }
    },
}