import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  { 
    path: '/404', 
    name: '404', 
    component: () => import('../views/404.vue'), 
  }, 
  { 
    path: '*', 
    redirect: '/404'
  },
  {
    path: '/timetable',
    name: 'Timetable',
    component: () => import('../views/Timetable.vue'),
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue'),
  },
  {
    path: '/literature',
    name: 'literature',
    component: () => import('../views/Literature.vue'),
  },
  {
    path: '/newmember',
    name: 'Newmember',
    component: () => import('../views/Newmember.vue'),
  },
  {
    path: '/members',
    name: 'Members',
    component: () => import('../views/Members.vue'),
  },
  {
    path: '/howcan',
    name: 'Howcan',
    component: () => import('../views/Howcan.vue'),
  },
  {
    path: '/twelvestep',
    name: 'Twelvestep',
    component: () => import('../views/TwelveStep.vue'),
  },
  {
    path: '/an-meeting',
    name: 'An-meeting',
    component: () => import('../views/Anmeeting.vue'),
  },
  {
    path: '/open-meeting',
    name: 'Open-meeting',
    component: () => import('../views/Openmeeting.vue'),
  },
  {
    path: '/adminpanel',
    name: 'adminpanel',
    meta: {auth: true},
    component: () => import('../views/AdminPanel'),
  },
  {
    path: '/addnews',
    name: 'Add news',
    meta: {auth: true},
    component: () => import('../views/AddNews'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: '/registration',
    name: 'FormRegistration',
    component: () => import('../views/FormRegistration')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// router.beforeEach((to, from, next)=>{
//   // const login
//   const requireLogin = to.matched.some(adminpanel = adminpanel.meta.auth)
//   console.log(requireLogin);
//   if(requireLogin){
//     next('/');
//   }else{
//     next();
//   }
// })

export default router
