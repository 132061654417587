<template>
  <header class="container-nav">
    <router-link to="/" class="logo">
      <div class="logo-img">
        <img
          src="../../public/logo.svg"
          alt="Анонимные Наркоманы город Смоленск"
        />
      </div>
      <span class="logo-descr">Анонимные Наркоманы город Смоленск</span>
    </router-link>

    <div class="contact">
      <phones />
    </div>
  </header>
</template>

<script>
import SocialLinks from "./Header/SocialLinks";
import Phones from "./Header/Phones";

export default {
  components: {
    SocialLinks,
    Phones,
  },
};
</script>

<style lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //width: 90%;
  padding: 20px 0;
  @media (max-width: 600px) {
    gap: 20px;
    justify-content: space-around;
    //flex-flow: column;
  }
}

.contact {
  display: flex;
  flex-direction: column;
}

.logo {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  text-decoration: none;
  color: #1c7fe2;

  img {
    max-width: 100%;
    width: 50px;
    height: 50px;
  }
  &-descr {
    max-width: 200px;
    margin-left: 12px;

    @media (max-width: 650px) {
      display: none;
    }
  }
  &-contact {
    @media (max-width: 650px) {
      flex-direction: column;
    }
    &__text {
      margin-bottom: 6px;
    }
  }
}
</style>